import * as React from 'react';
import Layout from '../components/layout';
import IncidentNotificationForm from '../components/incident-notification-form';
const formConfig = require('../components/incident-notification-form.json');

const IncidentNotoficationPage = () => (
  <Layout
    title={formConfig.title}
    storageKey="incidentnotification_stored"
  >
    <IncidentNotificationForm 
    />
  </Layout>
)

export default IncidentNotoficationPage;
