import * as React from 'react';
import { FormTitle, FieldList, FieldItem, FieldInfo, FormFooter, Checkbox, TextField, NoYesGroup } from './form';
import * as styles from './form.module.scss';
const formConfig = require('./incident-notification-form.json');

const IncidentNotificationFormStep4 = (props) => {
  const {
    formData, 
    formErrors,
    onChange, 
    onSubmit,
    onPrevious,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormTitle 
        text={formConfig.steps[3]}
      />
      <FieldList>
        <FieldItem>
          <FieldInfo
            {...formConfig.incidentNotificationScheme}
          />
          <Checkbox 
            label="Yes, I would like assistance"
            className="checkbox_alt"
            name="cirsAssistance"
            value={formData.cirsAssistance}
            error={formErrors.cirsAssistance}
            onChange={onChange}
          />
        </FieldItem>
        <FieldItem>
          <FieldInfo
            {...formConfig.privacyAssistance}
          />
          <Checkbox 
            label="Yes, I would like privacy assistance"
            className="checkbox_alt"
            name="privacyAssistance"
            value={formData.privacyAssistance}
            error={formErrors.privacyAssistance}
            onChange={onChange}
          />
        </FieldItem>
        <FieldItem>
          <FieldInfo 
            {...formConfig.typeOfInformation}
          />
          {formConfig.typeOfInformation.options.map((option, index) => (
            <Checkbox 
              key={index}
              label={option.label}
              name={option.name}
              value={formData[option.name]}
              error={formErrors[option.name]}
              onChange={onChange}
            />
          ))}
        </FieldItem>
        <FieldItem>
          <FieldInfo 
            {...formConfig.businessImpactLevel}
          />
          {formConfig.businessImpactLevel.options.map((option, index) => (
            <Checkbox 
              key={index}
              label={option.label}
              name={option.name}
              value={formData[option.name]}
              error={formErrors[option.name]}
              onChange={onChange}
            />
          ))}
        </FieldItem>
        <FieldItem>
          <FieldInfo 
            {...formConfig.informationFormat}
          />
          {formConfig.informationFormat.options.map((option, index) => (
            <Checkbox 
              key={index}
              label={option.label}
              name={option.name}
              value={formData[option.name]}
              error={formErrors[option.name]}
              onChange={onChange}
            />
          ))}
        </FieldItem>
        <FieldItem>
          <FieldInfo 
            {...formConfig.securityAttributes}
          />
          {formConfig.securityAttributes.options.map((option, index) => (
            <Checkbox 
              key={index}
              label={option.label}
              name={option.name}
              value={formData[option.name]}
              error={formErrors[option.name]}
              onChange={onChange}
            />
          ))}
        </FieldItem>
        <FieldItem>
          <FieldInfo 
            {...formConfig.incidentCaused}
          />
          {formConfig.incidentCaused.options.map((option, index) => (
            <Checkbox 
              key={index}
              label={option.label}
              name={option.name}
              value={formData[option.name]}
              error={formErrors[option.name]}
              onChange={onChange}
            />
          ))}
        </FieldItem>
        <FieldItem>
          <FieldInfo 
            {...formConfig.incidentResponsibility}
          />
          {formConfig.incidentResponsibility.options.map((option, index) => (
            <Checkbox 
              key={index}
              label={option.label}
              name={option.name}
              value={formData[option.name]}
              error={formErrors[option.name]}
              onChange={onChange}
            />
          ))}
        </FieldItem>
        <FieldItem>
          <FieldInfo 
            {...formConfig.threatType}
          />
          {formConfig.threatType.options.map((option, index) => (
            <Checkbox 
              key={index}
              label={option.label}
              name={option.name}
              value={formData[option.name]}
              error={formErrors[option.name]}
              onChange={onChange}
            />
          ))}
        </FieldItem>
        <FieldItem>
          <FieldInfo 
            {...formConfig.incidentClosed}
          />
          <NoYesGroup 
            name="incidentClosed"
            value={formData.incidentClosed}
            error={formErrors.incidentClosed}
            onChange={onChange}
          />
        </FieldItem>
        <FieldItem>
          <FieldInfo 
            {...formConfig.incidentRecorded}
          />
          <NoYesGroup 
            name="incidentRecorded"
            value={formData.incidentRecorded}
            error={formErrors.incidentRecorded}
            onChange={onChange}
          />
        </FieldItem>
        <FieldItem>
          <TextField 
            type="text"
            label="Enter the characters shown"
            name="captcha"
            error={formErrors.captcha}
            required={true}
            captcha={true}
          />
        </FieldItem>
      </FieldList>
      <FormFooter 
        next="Submit incident notification"
        onPrevious={onPrevious}
      />
    </form>
  )
}

export default IncidentNotificationFormStep4;
