import * as React from 'react';
import { FormTitle, FieldList, FieldItem, FormFooter, TextField, TextArea, FieldInfo } from './form';
import * as styles from './form.module.scss';
const globalConfig = require('./ovic-form.json');
const formConfig = require('./incident-notification-form.json');

const IncidentNotificationFormStep2 = (props) => {
  const {
    formData, 
    formErrors,
    onInput,
    onChange, 
    onSubmit,
    onPrevious,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormTitle 
        text={formConfig.steps[1]}
        description="This form should not be used by members of the public to report incidents, data breaches or alleged wrongdoing by VPS employees or organisations to OVIC. Individuals wishing to do so, should instead use <a href='https://make-a-privacy-complaint.ovic.vic.gov.au/'>OVIC's Privacy Complaint Form</a>."
      />
      <FieldList>
        <FieldItem className="fieldItem_nomargin">
          <FieldInfo 
            {...formConfig.organisationDetails}
          />
        </FieldItem>
        <FieldItem className="fieldItem_nomargin">
          <TextField 
            type="text"
            label="Name of Organisation reporting the incident"
            name="organisationName"
            value={formData.organisationName}
            error={formErrors.organisationName}
            characterCount={true}
            required={true}
            onChange={onChange}
            attrs={{
              maxLength: globalConfig.charLimits.xshort,
            }}
          />
        </FieldItem>
        <FieldItem className="fieldItem_nomargin">
          <TextField 
            type="text"
            label="Contact name and position of individual coordinating the incident on behalf of the above Organisation"
            description="This is the person OVIC will contact and liaise with in relation to the incident"
            name="organisationContact"
            value={formData.organisationContact}
            error={formErrors.organisationContact}
            characterCount={true}
            required={true}
            onChange={onChange}
            attrs={{
              maxLength: globalConfig.charLimits.xshort,
            }}
          />
        </FieldItem>
        <FieldItem className="fieldItem_nomargin">
          <TextField 
            type="tel"
            label="Contact phone number for the officer coordinating the incident to be used by OVIC"
            name="contactPhone"
            value={formData.contactPhone}
            error={formErrors.contactPhone}
            required={true}
            onInput={onInput}
            onChange={onChange}
            attrs={{
              input: '[0-9]',
              pattern: '[0-9]{10}',
              maxLength: 10,
            }}
          />
        </FieldItem>
        <FieldItem>
          <TextField 
            type="email"
            label="Contact email address for the officer coordinating the incident to be used by OVIC"
            description="Please be aware that a PDF copy of the form will automatically be sent to this email address upon submitting the form.<br /> Please ensure that the coordinating officer is aware, and care is taken to enter their email address correctly"
            name="contactEmail"
            value={formData.contactEmail}
            error={formErrors.contactEmail}
            required={true}
            onChange={onChange}
          />
        </FieldItem>
        <FieldItem className="fieldItem_nomargin">
          <FieldInfo 
            {...formConfig.incidentDetails}
          />
        </FieldItem>
        <FieldItem className="fieldItem_nomargin">
          <TextArea 
            label="What happened?"
            name="whatHappened"
            value={formData.whatHappened}
            error={formErrors.whatHappened}
            required={true}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.long,
            }}
          />
        </FieldItem>
        <FieldItem className="fieldItem_nomargin">
          <TextArea 
            label="When did it happen?"
            name="whenDidItHappen"
            value={formData.whenDidItHappen}
            error={formErrors.whenDidItHappen}
            required={true}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.medium,
            }}
          />
        </FieldItem>
        <FieldItem className="fieldItem_nomargin">
          <TextArea 
            label="When did the organisation become aware of it?"
            name="whenDidOrganisationBecomeAware"
            value={formData.whenDidOrganisationBecomeAware}
            error={formErrors.whenDidOrganisationBecomeAware}
            required={true}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.medium,
            }}
          />
        </FieldItem>
        <FieldItem className="fieldItem_nomargin">
          <TextArea 
            label="How did it happen?"
            name="howDidItHappen"
            description="<p>For example:</p><ul><li>Who and/or what caused it?</li><li>Was it malicious or accidental?</li><li>Who accessed information in an unauthorised manner?</li><li>Please be as specific as possible. E.g. if referring to third party, please provide the name of the third party or describe the nature of the third party.</li></ul>"
            value={formData.howDidItHappen}
            error={formErrors.howDidItHappen}
            required={true}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.medium,
            }}
          />
        </FieldItem>
        <FieldItem className="fieldItem_nomargin">
          <TextArea 
            label="What steps have been taken, or are proposed to be taken, to contain the incident?"
            name="whatStepsToContain"
            value={formData.whatStepsToContain}
            error={formErrors.whatStepsToContain}
            required={true}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.medium,
            }}
          />
        </FieldItem>
        <FieldItem>
          <TextArea 
            label="What steps will be taken or proposed to prevent future incidents?"
            name="whatStepsToPrevent"
            value={formData.whatStepsToPrevent}
            error={formErrors.whatStepsToPrevent}
            required={true}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.medium,
            }}
          />
        </FieldItem>
      </FieldList>
      <FormFooter 
        next="Next: Privacy incidents"
        onPrevious={onPrevious}
      />
    </form>
  )
}

export default IncidentNotificationFormStep2;
