import * as React from 'react';
import { FormTitle, FormConfirmation } from './form';
import * as styles from './form.module.scss';
const formConfig = require('./incident-notification-form.json');

const IncidentNotificationFormStep5 = (props) => {
  return (
    <form
      className={styles.page}>
      <FormTitle 
        text={formConfig.steps[4]}
      />
      <FormConfirmation>
        <p>Thank you for notifying OVIC of this incident.</p>
        <p>You will now receive an email with a copy of your incident for your own records.</p>
        <p>To find out what you can expect next from OVIC’s incident notification process, please see <a href="https://ovic.vic.gov.au/data-protection/agency-reporting-obligations/incident-notification/#what-happens-after-ovic-is-notified-of-an-incident" target="_blank" rel="noopener noreferrer">what happens after OVIC is notified of an incident</a>.</p>
      </FormConfirmation>
    </form>
  )
}

export default IncidentNotificationFormStep5;
