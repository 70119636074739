import * as React from 'react';
import { FormTitle, FieldList, FieldItem, FieldInfo, FieldMessage, FormFooter, TextArea } from './form';
import * as styles from './form.module.scss';
const globalConfig = require('./ovic-form.json');
const formConfig = require('./incident-notification-form.json');

const IncidentNotificationFormStep3 = (props) => {
  const {
    formData, 
    formErrors,
    onChange, 
    onSubmit,
    onPrevious,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormTitle 
        text={formConfig.steps[2]}
      />
      <FieldList>
        <FieldItem>
          <FieldInfo
            {...formConfig.incidentPersonal}
          />
          <TextArea 
            label="What personal information is involved in the incident?"
            name="personalInformation"
            value={formData.personalInformation}
            error={formErrors.personalInformation}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.medium,
            }}
          />
          <TextArea 
            label="What is the risk of harm to the affected individuals?"
            description="<p>What type of harm? How serious? How likely?</p>"
            name="personalInformationRisk"
            value={formData.personalInformationRisk}
            error={formErrors.personalInformationRisk}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.medium,
            }}
          />
          <TextArea 
            label="Have affected individuals been notified about the incident?"
            description="<p>If not, why? If so, how? What was the reactions?</p>"
            name="personalInformationNotified"
            value={formData.personalInformationNotified}
            error={formErrors.personalInformationNotified}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.medium,
            }}
          />
          <FieldMessage>
            <p>Visit our website for more information on <a href="https://ovic.vic.gov.au/privacy/information-security-and-privacy-incident-notification-form/" target="_blank" rel="noopener noreferrer">managing the privacy impacts of a data breach</a>.</p>
          </FieldMessage>
        </FieldItem>
      </FieldList>
      <FormFooter 
        next="Next: Incident notification scheme"
        onPrevious={onPrevious}
      />
    </form>
  )
}

export default IncidentNotificationFormStep3;
